/**
 * @license
 * MyFonts Webfont Build ID 3361386, 2017-03-18T08:02:38-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir-pro/pro-65-medium/
 *
 * Webfont: AvenirLTPro-Book by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir-pro/pro-45-book/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3361386
 * Licensed pageviews: 250,000
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 *
 * © 2017 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("http://hello.myfonts.net/count/334a6a");

@font-face {
  font-family: "AvenirLTPro-Medium";
  src: url("./webfonts/334A6A_0_0.eot");
  src: url("./webfonts/334A6A_0_0.eot?#iefix") format("embedded-opentype"),
    url("./webfonts/334A6A_0_0.woff2") format("woff2"),
    url("./webfonts/334A6A_0_0.woff") format("woff"),
    url("./webfonts/334A6A_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLTPro-Book";
  src: url("./webfonts/334A6A_1_0.eot");
  src: url("./webfonts/334A6A_1_0.eot?#iefix") format("embedded-opentype"),
    url("./webfonts/334A6A_1_0.woff2") format("woff2"),
    url("./webfonts/334A6A_1_0.woff") format("woff"),
    url("./webfonts/334A6A_1_0.ttf") format("truetype");
}
